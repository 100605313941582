'use client';

import { forwardRef } from 'react';
import { Control, Controller, FieldValue, FieldValues } from 'react-hook-form';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Icon, Portal, SelectProps, Value } from '@radix-ui/react-select';

import * as S from './styles';

export type item = {
  label: string | number;
  value: string | number;
  icon?: React.ReactNode;
};

export type ISelect = {
  icon?: React.ReactNode;
  items: item[];
  label?: string;
  name: string;
  classNameWrapper?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  control?: Control<FieldValue<FieldValues>>;
  onChange?: (value: string) => void;
  noneOption?: boolean;
} & SelectProps;

const Select = forwardRef<HTMLDivElement, ISelect>(
  (
    {
      icon,
      items,
      label,
      classNameWrapper,
      name,
      placeholder,
      error,
      errorMessage,
      control,
      noneOption = false,
      ...props
    },
    ref
  ) => {
    const itemsWithNone = noneOption
      ? [{ label: 'Nenhuma', value: '' }, ...items]
      : items;
    return (
      <div ref={ref} className={classNameWrapper}>
        <>
          {label && <S.Label htmlFor={name}>{label}</S.Label>}
          <Controller
            defaultValue={props.defaultValue}
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <S.SelectRoot
                  value={field.value}
                  {...props}
                  defaultValue={field.value}
                  onValueChange={(value) => {
                    if (value === '') {
                      field.onChange('');
                    } else {
                      field.onChange(value);
                    }
                    props.onChange && props.onChange(value);
                  }}>
                  <S.SelectTrigger aria-label={label}>
                    {icon && <Icon className="selectIcon">{icon}</Icon>}
                    <Value placeholder={placeholder} aria-label={label} />
                    <Icon className="selectIcon">
                      <KeyboardArrowDownIcon />
                    </Icon>
                  </S.SelectTrigger>
                  <S.SelectContent position="popper">
                    <S.ViewPort>
                      {!props.disabled &&
                        itemsWithNone?.length > 0 &&
                        itemsWithNone?.map((item, index) => (
                          <S.SelectItem key={index} value={item.value as never}>
                            <S.SelectLabel>{item.label}</S.SelectLabel>
                          </S.SelectItem>
                        ))}
                    </S.ViewPort>
                  </S.SelectContent>
                </S.SelectRoot>
              );
            }}
          />

          {error && errorMessage && (
            <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
          )}
        </>
      </div>
    );
  }
);

Select.displayName = 'Select';

export { Select };
